<template>
  <div class="form">
    <el-form :inline="true">
      <el-form-item>
        <el-button class="add-btn" @click="showAdd">添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
    showAdd() {
      this.$emit('show-add');
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 160px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .el-select {
    width: 100px;
  }
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
</style>
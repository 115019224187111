import API from "../../../api/index";

export default {
  getAccountList: params => {
    return API.requestGet({
      params,
      url: "loan/plan/list",
    });
  },
  // 添加子账号
  add: params => {
    return API.requestPost({
      data: params,
      url: "loan/plan/create"
    });
  },
  // 编辑子账号
  edit: params => {
    return API.requestPost({
      data: params,
      url: "loan/plan/modify"
    });
  },
  del: params => {
    return API.requestPost({
      data: params,
      url: "loan/plan/remove"
    });
  },
};
